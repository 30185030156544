import React from 'react';

export default function BulkActions({
  filters, supplierInvoices, onToggleBulkActions, onRemove, onRestore, onSendToErp,
  onExport, onSendReminder, integrationType, onMarkAsPaid, standAlone,
}) {
  const selectedSupplierInvoices = supplierInvoices.length;

  const showOnlyRestoreAction = () => {
    if ((Array.isArray(filters.deleted) && filters.deleted.includes('1')) || filters.deleted === '1') return true;
    return false;
  };

  const renderSentToErpOrExport = () => {
    const allApproved = supplierInvoices.every(supplierInvoice => supplierInvoice.state === 'approved');
    const allTansferred = supplierInvoices.every(supplierInvoice => supplierInvoice.state === 'transferred');
    const allSentToApprove = supplierInvoices.every(supplierInvoice => supplierInvoice.state === 'sent_to_approve');
    if (allTansferred && integrationType === 'manual') {
      return <label onClick={() => onExport()}>{I18n.t('purchase_orders.invoices.resend_to_erp')}</label>;
    }
    if (allApproved) {
      return <label onClick={() => onSendToErp()}>{I18n.t('purchase_orders.invoices.send_to_erp')}</label>;
    }
    if (allSentToApprove) {
      return <label onClick={() => onSendReminder()}>{I18n.t('purchase_orders.invoices.send_reminder')}</label>;
    }
  };

  return (
    <div className="table-bulk" style={{ display: 'flex' }}>
      <div className="actions">
        <div className="items items-divided">
          <span>
            <span className="selected" data-selected="selected">
              {selectedSupplierInvoices}
              {' '}
              {I18n.t('purchase_orders.invoices.selected')}
            </span>
          </span>
          <span>
            <span className="select input-s select-transparent display-inline-block">
              <input type="checkbox" />
              <div className="select-content">
                {showOnlyRestoreAction()
                  ? <label onClick={() => onRestore()}>{I18n.t('purchase_orders.invoices.restore')}</label>
                  : (
                    <React.Fragment>
                      {renderSentToErpOrExport()}
                      {standAlone && <label onClick={() => onMarkAsPaid()}>{I18n.t('purchase_orders.invoices.mark_as_paid')}</label>}
                      <label onClick={() => onRemove()}>{I18n.t('purchase_orders.invoices.remove')}</label>
                    </React.Fragment>
                  )
                }
                <div className="placeholder" data-placeholder={I18n.t('purchase_orders.invoices.bulk_action')} />
              </div>
            </span>
          </span>
        </div>
      </div>
      <label onClick={onToggleBulkActions} className="icon-close" htmlFor="table-bulk" />
    </div>
  );
}
